<template>
  <div class="card card-custom" :style="heightComponentInlineStyle">
    <div class="card-body d-flex flex-column justify-content-between">
      <b-table
        table-class="reisetermin-table"
        :key="'b-table-reisetermin-table'"
        :id="'b-table-reisetermin-table'"
        :ref="'b-table-reisetermin-table'"
        :items="items"
        :fields="filteredFields"
        :sort-by="'startdatum'"
        no-local-sorting
        sort-icon-left
        fixed
        show-empty
        :sticky-header="tableHeight + 'px'"
        no-sort-reset
        :busy="isBusy"
        :empty-text="filterMessage || $t('COMMON.NODATAFOUND')"
        thead-class="text-nowrap"
        @sort-changed="$emit('sortChange', $event)"
      >
        <template #cell(garantiert)="row">
          <i :class="row.item.garantiert ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
        </template>

        <template #cell(stopSale)="{ row, item }">
          <i v-if="item.stopSale" class="flaticon2-exclamation mr-1 text-white rounded bg-danger" />
        </template>

        <template v-slot:cell(startdatum)="row">
          {{ row.item.startdatum | date }}
        </template>

        <template v-slot:cell(releasedatum)="row">
          <slot name="releasedatum" :item="row.item">
            <span :title="$t('REISEN.RELEASEDATUMWITHEXPLENATION')">
              {{ row.item.releasedatum | date }}
              {{ getReleaseStartDateDiff(row.item.releasedatum, row.item.startdatum) }}
            </span>
          </slot>
        </template>

        <template v-slot:cell(pax)="row">
          <PaxWidget :paxObject="row.item.pax" :kalkPax="row.item.metadata.kalkPax"></PaxWidget>
        </template>

        <template v-slot:cell(state)="row">
          <b-badge>{{ getStateGerman(row.item.state) }}</b-badge>
        </template>

        <template v-slot:cell(actions)="row">
          <ActionButton
            :link="{ name: 'reisetermine-view', params: { reiseterminId: row.item.id } }"
            title="Reisetermin Detailansicht"
            iconClass="fas fa-info"
          />
          <ActionButton
            @click="onToggleDetailsClick($event, row)"
            :iconClass="row.detailsShowing ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
            title="Ausklappen"
          />
        </template>

        <template slot="row-details" slot-scope="row" v-if="row.item._showDetails">
          <ReiseterminTableDetails :reiseterminObject="row.item"></ReiseterminTableDetails>
        </template>
      </b-table>
      <SKRPagination
        v-model="currentPage"
        :isLoading="isBusy"
        :totalRows="count"
        :currentRows="items.length || 0"
        ariaControls="reiseterminTable"
        @pageChange="onPageChange"
      ></SKRPagination>
    </div>
  </div>
</template>

<style lang="scss">
.table-dropdown > .btn {
  padding: 0;
}
.cw-50 {
  width: 50px;
}
.cw-80 {
  width: 80px;
}
.cw-100 {
  width: 100px;
}
.cmw-100 {
  min-width: 100px;
}
.cw-125 {
  width: 125px;
}
.cw-150 {
  width: 150px;
}
.cw-300 {
  width: 300px;
}
.columnWidth200 {
  width: 200px;
}
.columnWidth350 {
  width: 350px;
}
</style>

<script>
import { mapState } from 'vuex';

import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import ActionButton from '@/components/common/action-button.vue';

import StateWidget from '@/components/common/state-widget.vue';
import ReiseterminTableDetails from '@/components/produkte/reisetermine/reisetermine-table-details';
import SKRPagination from '@/components/common/skr-pagination.vue';
import { calculateReleaseStartDateDiff } from '@/core/common/helpers/utils';

import { trackEventWithRouteAndUser } from '@/core/common/services/application-insights.service';

import reiseterminTableFields from '@/core/produkte/reisetermine/reisetermin-table-fields.config.js';

export default {
  name: 'ReiseterminTable',
  props: {
    items: {
      type: Array,
    },
    count: { type: Number },
    isInitiallyLoading: { type: Boolean },
    isBusy: {
      type: Boolean,
    },
    hideFieldsByKey: { type: Array, default: null },
    tableHeight: {
      type: Number,
      required: false,
    },
    filterMessage: {
      type: String,
      required: false,
    },
    heightComponentInlineStyle: {
      type: String,
      required: false,
    },
  },
  components: {
    StateWidget,
    PaxWidget,
    ReiseterminTableDetails,
    SKRPagination,
    ActionButton,
  },
  data() {
    return {
      workflowObj: null,
      workflowObjId: null,
      currentPage: 1,
      selected: [],
      fields: [
        {
          key: 'reise.reisekuerzel',
          label: this.$t('REISEN.COMMON.REISE'),
          sortable: true,
          class: 'position-sticky cw-80 ',
        },
        {
          key: 'startdatum',
          label: this.$t('REISEN.COMMON.STARTDATE'),
          sortable: true,
          class: 'position-sticky cw-125',
        },
        {
          key: 'metadata.reisedauer',
          label: 'Reisedauer',
          sortable: true,
          class: 'position-sticky cw-100',
          formatter: value => {
            return value || '-';
          },
        },
        { ...reiseterminTableFields.garantiert },
        { ...reiseterminTableFields.stopSale },
        {
          key: 'releasedatum',
          sortable: true,
          class: 'position-sticky cw-125 ',
        },
        {
          key: 'umsatz',
          label: this.$t('REISEN.COMMON.SALES'),
          sortable: true,
          class: 'position-sticky cw-100',
          tdClass: 'text-right',
          formatter: value => {
            return this.$options.filters.currency(value) || '-';
          },
        },
        {
          key: 'basisPreis',
          label: this.$t('REISEN.COMMON.BASISPREIS'),
          sortable: true,
          class: 'position-sticky cw-100',
          tdClass: 'text-right',
          formatter: value => {
            return this.$options.filters.currency(value) || '-';
          },
        },
        {
          key: 'pax',
          label: 'PAX',
          sortable: false,
          class: 'columnWidth200 b-table-sort-icon-left',
        },
        {
          key: 'state',
          label: this.$t('REISEN.COMMON.APPOINTMENTSTATE'),
          sortable: false,
          class: 'cw-125 b-table-sort-icon-left',
        },
        {
          key: 'actions',
          label: this.$t('REISEN.COMMON.ACTIONS'),
          class: 'cw-125 b-table-sort-icon-left ',
        },
      ],
    };
  },
  watch: {
    triggerSent(v) {
      const index = this.items.findIndex(i => i.id === v.id);
      this.$emit('update-single-item', index, v.id);
    },
  },
  computed: {
    ...mapState({
      triggerSent: state => state.workflowManagerReisetermin.triggerSent,
    }),
    filteredFields() {
      if (this.hideFieldsByKey !== null) {
        return this.fields.filter(f => !this.hideFieldsByKey.includes(f.key));
      } else {
        return this.fields;
      }
    },
  },
  methods: {
    getStateGerman(value) {
      return value !== null && value !== undefined ? this.$t('REISETERMINE.states.' + value) : value;
    },
    onPageChange($event) {
      this.$emit('pageChange', $event);
      this.$refs['b-table-reisetermin-table'].$el.scrollTop = 0;
    },
    onToggleDetailsClick($event, row) {
      row.toggleDetails();
      trackEventWithRouteAndUser(
        { name: 'Toggled Reisetermin Overview Details' },
        { reiseterminId: row.item.id, detailsShowing: row.detailsShowing }
      );
    },
    getReleaseStartDateDiff(releasedatum, startdatum) {
      return calculateReleaseStartDateDiff(releasedatum, startdatum);
    },
  },
};
</script>
